



























import { Component, Vue } from 'vue-property-decorator'
import { getPreviewChannelMsg } from '@/api'
import moment from 'moment'
@Component({
  name: 'preview',
  filters: {
    dateFormat: (value: any, pattern: any) => {
      return moment(value).format(pattern)
    }
  }
})
export default class Preview extends Vue {
  [x: string]: any
  ruleForm = {
    // 频道名称
    channelName: '',
    // 频道标题
    msgTitle: '',
    // 频道内容
    msgText: '',
    // 发布时间
    createTime: 0,
    // 发布位置
    address: '',
    channelHeadUrl: ''
  }

  created() {
    // 获取预览详情
    this.getPreviewChannelMsg()
  }

  // 获取预览详情
  async getPreviewChannelMsg() {
    const { data } = await getPreviewChannelMsg({
      msgId: this.$route.query.msgId
    })
    this.ruleForm = { ...data }
  }
}
